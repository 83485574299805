<template>
    <div>
        <Header />

        <div class="mt-4 projects">
            <div>
                <h2 class="advert mb-4 text-center">
                    <b-icon icon="arrow-right" class="u-text-highlight mr-3" />
                    <a class="u-text-highlight" href="https://blog.nick-hat-boecker.de">Zum <span class="d-none d-sm-inline">Programmierer</span> Blog</a>
                    <b-icon icon="arrow-left" class="u-text-highlight ml-3" />
                </h2>
            </div>
            <div class="u-separator pt-4">
                <h2 class="h1 mb-4 u-text-primary">Portfolio</h2>
                <Project
                    class="mb-4"
                    v-bind="project"
                    v-for="(project, index) in projects"
                    :key="`project-${index}`"
                    :isFirst="index === 0"
                />
            </div>
            <Contact />
        </div>
    </div>
</template>

<script>
import Project from '../components/Project'
import Header from '../components/Header'
import Contact from "../components/Contact";

export default {
    name: 'Home',

    components: { Contact, Header, Project },

    computed: {
        projects () {
            return this.$store.state.projects
        },
    },
}
</script>
